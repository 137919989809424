import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import BlogsImg from "./BlogsImg";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import CalendlyWidgetInline from "../../components/CalendlyWidgetInline/CalendlyWidgetInline";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              {/*<div className="contact-heading-img-div">*/}
              {/*  <img*/}
              {/*    src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}*/}
              {/*    alt=""*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {ContactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                <div className="contact-btns-div">
                  <div className="resume-btn-div ">
                    <Button
                        text="See My Resume"
                        newTab={true}
                        href={greeting.resumeLink}
                        theme={theme}
                    />

                  </div>


                </div>


              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">

          </Fade>

        </div>

<div className="pushed-to-end"> <Footer theme={this.props.theme} onToggle={this.props.onToggle} /></div>

        <CalendlyWidgetInline/>
      </div>
    );
  }
}

export default Contact;
